body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background-color: #efefef;
}

.slider img {
  height: 512px;
  object-fit: cover;
}

#printable {
  display: none;
}

nav {
  padding: 0;
  margin: 0;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1;
}

nav ul .menuicon {
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

nav ul li {
  display: inline-block;
}

.main-menu {
   position: relative;
 }

nav ul li div {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
}

nav ul li:hover>ul {
  display: block;
}

nav ul ul {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  left: 0;
  /* Added left 100% */
  top: 100%;
  /* Added top 0 */
  margin-top:2
}

nav ul ul li {
  display: block;
}

nav ul ul li div {
  padding: 10px 20px;
  color: #666;
}

nav ul ul li div:hover {
  background-color: #f5f5f5;
}

.sub-submenu {
  position: absolute;
  /* Added position relative */
  left: 100%;
  /* Added left 100% */
  top: 0;
  /* Adjust the value to position it properly */
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

/* Style the content within the container */
.not-found-content {
  text-align: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-form {
  width: 280px;
}

.login-form-remember {
  float: left;
}

.login-form-forgot {
  float: right;
  margin-top: 5px;
}

.login-form-button {
  width: 100%;
}

ul {
  list-style: none;
  font-size: 24px;
}

@media print {
  #non-printable {
    display: none;
  }

  #printable {
    display: block;
  }
}

@media only screen and (max-device-width: 480px) {
  .slider img {
    height: 320px;
  }
}